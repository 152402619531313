import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { defaultToast, errorToast } from '../../Utils/Toast';
import CreateInvoiceTable from '../../Components/CreateInvoiceTable/CreateInvoiceTable';
import "./Billing.css";

const CreateInvoice = () => {
  const navigate = useNavigate();

  const [customerName, setCustomerName] = useState('');
  const [customerGST, setCustomerGST] = useState('');
  const [customerPhone, setCustomerPhone] = useState('');
  const [productName, setProductName] = useState('');
  const [productCost, setProductCost] = useState('');
  const [productQuantity, setProductQuantity] = useState('');
  const [quantityUnit, setQuantityUnit] = useState('dozen');

  const [isDozen, setIsDozen] = useState(false);
  const [qtyInPieces, setQtyInPieces] = useState(0);

  const [productList, setProductList] = useState([]);
  const [idList, setIdList] = useState([]);
  const [nameList, setNameList] = useState([]);
  const [quantityList, setQuantityList] = useState([]);
  const [priceList, setPriceList] = useState([]);
  const [productSuggestions, setProductSuggestions] = useState([]);

  useEffect(() => {
    if (localStorage.getItem("token") || localStorage.getItem("isAdmin")) {
      getProducts();
    } else {
      navigate("/login");
    }

    // eslint-disable-next-line
  }, []);

  const getProducts = async () => {
    const resp = await fetch("https://api.sushilbanglestore.com/products");
    const productResp = await resp.json();

    const productsData = productResp.response.map((p) => ({
      id: p._id,
      name: p.name,
      quantity: p.quantity,
      price: p.price,
    }));

    // Extract product names for suggestions
    const productNames = productsData.map(product => product.name);

    setIdList(productsData.map(product => product.id));
    setNameList(productNames);
    setQuantityList(productsData.map(product => product.quantity));
    setPriceList(productsData.map(product => product.price));

    setProductSuggestions(productNames); // Update suggestions
  }

  const handleProductNameChange = (e) => {
    const input = e.target.value;
    setProductName(input);

    // Filter suggestions based on user input
    const filteredSuggestions = nameList.filter(name =>
      name.toLowerCase().includes(input.toLowerCase())
    );

    setProductSuggestions(filteredSuggestions);
  };

  const handleRemoveProduct = (index) => {
    const productListCopy = [...productList];
    productListCopy.splice(index, 1);
    setProductList(productListCopy);
  }

  const handleSuggestionClick = (suggestion) => {
    setProductName(suggestion);
    setProductSuggestions([]); // Clear suggestions when a suggestion is clicked

    const productIndex = nameList.findIndex(
      (name) => name.toLowerCase() === suggestion.toLowerCase()
    );

    let unit;

    if (productIndex !== -1) {
      unit = priceList[productIndex].split(" ")[2];
      setQuantityUnit(unit);

      if (unit === "dozen") setIsDozen(true);
      else {
        setIsDozen(false);
        setQtyInPieces(0);
      }
    }

  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (productName.trim() && productCost > 0 && productQuantity >= 1) {
      // Find the index of the selected product name in nameList
      const productIndex = nameList.findIndex(
        (name) => name.toLowerCase() === productName.toLowerCase()
      );

      // Check if the product exists in the list
      if (productIndex !== -1) {
        const availableQuantity = parseInt(
          quantityList[productIndex].split(" ")[0]
        );

        // Check if the entered quantity is valid
        if (parseInt(productQuantity) <= availableQuantity) {
          // Product has sufficient quantity

          // Check for pieces entered lies between 0-11
          if (qtyInPieces >= 0 && qtyInPieces <= 11) {
            setProductList([
              ...productList,
              {
                productName,
                productCost,
                productQuantity,
                quantityUnit,
                isDozen,
                qtyInPieces
              },
            ]);

            defaultToast("Product Added");

            setProductName('');
            setProductCost('');
            setProductQuantity('');
            setIsDozen(false);
            setQtyInPieces(0);
          } else errorToast("Pieces can be between 0-11 only")
        } else {
          // Handle insufficient quantity
          setProductQuantity('');
          errorToast(`Insufficient quantity available for ${nameList[productIndex]}.`);
          errorToast(`Only ${availableQuantity} ${quantityUnit}s are available`);
        }
      } else {
        errorToast("Product not found in the database.");
      }
    }
  };

  const saveInvoice = async (e) => {
    e.preventDefault();

    if (productList.length > 0) {
      try {
        for (const product of productList) {
          const productIndex = nameList.findIndex(
            (name) => name.trim().toLowerCase() === product.productName.trim().toLowerCase()
          );

          if (productIndex !== -1) {
            const availableQuantity = parseInt(
              quantityList[productIndex].split(" ")[0]
            );

            if (availableQuantity >= product.productQuantity) {
              // Deduct the quantity
              const newQuantity = availableQuantity - product.productQuantity - (product.qtyInPieces / 12);
              const productId = idList[productIndex];

              // Update the quantity in DB
              await fetch(`https://api.sushilbanglestore.com/products/${productId}`, {
                method: "PUT",
                headers: { 'Content-Type': "application/json" },
                body: JSON.stringify({
                  quantity: `${newQuantity} ${product.quantityUnit}`,
                })
              });

              defaultToast("Quantity updated");
            } else {
              errorToast(`Insufficient quantity available for the product: ${product.productName}`);
            }
          } else {
            errorToast(`Product not found in the database: ${productName}`);
          }
        }

        const isConfirmed = window.confirm("SAVE INVOICVE ?");
        
        if (isConfirmed){
          // Save invoice
          const resp = await fetch("https://api.sushilbanglestore.com/invoices", {
            method: "POST",
            headers: { 'Content-Type': "application/json" },
            body: JSON.stringify({
              customerName,
              customerGST,
              customerPhone,
              productList,
              createdBy: localStorage.getItem("userName")
            })
          });

          const data = await resp.json();

          // Getting the invoices
          const invData = await fetch("https://api.sushilbanglestore.com/invoices");
          const inv = await invData.json();
          const invLength = inv.response.length;

          // Redirect to view-invoice
          setTimeout(() => {
            navigate(`/view-invoice/${data.response._id} ${invLength}`);
          }, 1000);


          defaultToast("Invoice Saved successfully!");

          // Clear productList and form fields
          setProductList([]);
          setCustomerName("");
          setCustomerGST("");
          setCustomerPhone("");

          // Refresh products
          // getProducts();
        }
      } catch (error) {
        // Handle any errors that occurred during the process
        errorToast(error.message);
      }
    } else {
      errorToast("Please add some products first")
    }
  };

  return (
    <>
      <div className="w-full p-3 md:w-2/3 shadow-xl mx-auto rounded my-2 md:p-8">
        <h3 className="text-center font-bold text-xl mb-4">
          Create an Invoice
        </h3>

        <form
          className="w-full mx-auto flex flex-col">
          <label htmlFor="customerName" className="text-sm">
            Customer's Name
          </label>
          <input
            placeholder="Enter Customer Name"
            type="text"
            required
            name="customerName"
            className="py-2 px-4 bg-gray-100 w-full mb-6"
            value={customerName}
            onChange={(e) => setCustomerName(e.target.value)}
          />

          <label htmlFor="customerGST" className="text-sm">
            Customer's GST
          </label>
          <input
            placeholder="Enter Customer GST"
            type="text"
            required
            name="customerGST"
            className="py-2 px-4 bg-gray-100 w-full mb-6"
            value={customerGST}
            onChange={(e) => setCustomerGST(e.target.value)}
          />

          <label htmlFor="customerPhone" className="text-sm">
            Customer's Phone Number
          </label>
          <input
            placeholder="Enter Customer Phone Number"
            type="number"
            required
            name="customerPhone"
            className="py-2 px-4 bg-gray-100 w-full mb-6"
            value={customerPhone}
            onChange={(e) => setCustomerPhone(e.target.value)}
          />

          <div className="w-full flex justify-between flex-col">
            <h3 className="my-4 font-bold">Products List</h3>

            <div className="flex space-x-3 mobile-view">

              <div className={`flex flex-col ${isDozen ? 'w-1/5' : 'w-1/4'}`}>
                <label htmlFor="itemName" className="text-sm">
                  Product Name
                </label>
                <input
                  type="text"
                  name="itemName"
                  placeholder="Name"
                  className="py-2 px-4 mb-6 bg-gray-100"
                  value={productName}
                  onChange={handleProductNameChange} // Update product name input
                />

                {/* Display suggestions */}
                {productSuggestions.length > 0 && productName && (<>
                  <p><strong>Suggestions</strong> ⬇️</p>
                  <ul className="suggestions">
                    {productSuggestions.map((suggestion) => (
                      <li
                        key={suggestion}
                        onClick={() => handleSuggestionClick(suggestion)}
                        className="suggestion-item cursor-pointer m-2">
                        {suggestion}
                      </li>
                    ))}
                  </ul>
                </>
                )}
              </div>

              <div className={`flex flex-col ${isDozen ? 'w-1/5' : 'w-1/4'}`}>
                <label htmlFor="itemQuantity" className="text-sm">
                  Product Quantity {isDozen ? "in Dozens" : ""}
                </label>
                <input
                  type="number"
                  name="itemQuantity"
                  placeholder={`Quantity in ${quantityUnit}`}
                  className="py-2 px-4 mb-6 bg-gray-100"
                  value={productQuantity}
                  onChange={(e) => setProductQuantity(e.target.value)}
                />
              </div>

              {/* Extra Input in case of Dozen Unit */}
              {
                isDozen
                  ? <div className={`flex flex-col ${isDozen ? 'w-1/5' : 'w-1/4'}`}>
                    <label htmlFor="itemQuantityInPieces" className="text-sm">
                      Product Quantity in Pieces
                    </label>
                    <input
                      type="number"
                      name="itemQuantityInPieces"
                      min={0}
                      max={12}
                      placeholder="Enter Pieces"
                      className="py-2 px-4 mb-6 bg-gray-100"
                      value={qtyInPieces}
                      onChange={(e) => setQtyInPieces(e.target.value)}
                    />
                  </div>
                  : ""
              }

              <div className={`flex flex-col ${isDozen ? 'w-1/5' : 'w-1/4'}`}>
                <label htmlFor="itemCost" className="text-sm">
                  Product Price
                </label>
                <input
                  type="number"
                  name="itemCost"
                  placeholder={`Price per ${quantityUnit}`}
                  className="py-2 px-4 mb-6 bg-gray-100"
                  value={productCost}
                  onChange={(e) => setProductCost(e.target.value)}
                />
              </div>

              <div className={`flex flex-col ${isDozen ? 'w-1/5' : 'w-1/4'}`}>
                <p className="text-sm">Total Price</p>
                <p className="py-2 px-4 mb-6 bg-gray-100">
                  ₹ {(productCost * (Number(productQuantity) + (Number(qtyInPieces) / 12))).toFixed(2)}
                </p>
              </div>
            </div>

            <button
              className="bg-[#670006] text-gray-100 w-[150px] p-3 rounded my-2"
              onClick={handleSubmit}>
              Add Product
            </button>
          </div>

          {/* ProductList Table */}
          {productList[0] && (
            <CreateInvoiceTable
              productList={productList}
              handleRemove={handleRemoveProduct}
            />
          )}


          <button
            className="bg-[#670006] text-gray-100 w-full p-[12px] rounded my-6 mx-auto"
            type="submit" onClick={saveInvoice}>
            SAVE INVOICE
          </button>
        </form>
      </div>
    </>
  );
};

export default CreateInvoice;