import React from 'react';
import { calculateGrandTotal } from '../../Utils/functions';

const CreateInvoiceTable = ({ productList, handleRemove }) => {
  return (
    <table>
      <thead>
        <tr>
          <th>S.No.</th>
          <th>Name</th>
          <th>Quantity</th>
          <th>Price</th>
          <th>Amount</th>
          <th>Action</th>
        </tr>
      </thead>

      <tbody>
        {productList.reverse().map((product, index) => (
          <tr key={product.productName}>
            <td className="text-sm">{index + 1}</td>
            <td className="text-sm">{product.productName}</td>
            <td className="text-sm">{product.productQuantity} {product.quantityUnit}s {product.isDozen ? ` + ${product.qtyInPieces} Pieces` : ""}</td>
            <td className="text-sm">₹ {product.productCost}</td>
            <td className="text-sm">
              ₹ {(product.productCost * (Number(product.productQuantity) + (Number(product.qtyInPieces) / 12))).toFixed(2)}
            </td>
            <td><button className="btn btn-danger" onClick={() => {
              const confirmRemove = window.confirm("Remove product?");
              if (confirmRemove) handleRemove(index);
            }}><i className="fa-sharp fa-solid fa-trash"></i> Remove Product </button></td>
          </tr>
        ))}

        <tr>
          <td colSpan="4" className="text-right font-bold text-sm">
            TOTAL AMOUNT
          </td>
          <td className="font-bold text-right text-sm">
            ₹ {calculateGrandTotal(productList)}
          </td>
        </tr>
      </tbody>
    </table>
  );
};

export default CreateInvoiceTable;