import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { errorToast } from "../../Utils/Toast";
import { formatDate } from "../../Utils/functions";

const Orders = () => {
  const navigate = useNavigate();

  const [invoices, setInvoices] = useState([]);

  useEffect(() => {
    if (localStorage.getItem("token") || localStorage.getItem("isAdmin")) getInvoices();
    else navigate("/login");

    // eslint-disable-next-line
  }, []);

  const getInvoices = async () => {
    try {
      const invoicesResp = await fetch("https://api.sushilbanglestore.com/invoices");
      const resp = await invoicesResp.json();

      let invoicesList = [];

      resp.response.forEach(i => {
        invoicesList.push({ data: i, id: i._id });
      });

      setInvoices(invoicesList);
    } catch (e) {
      console.log(e);
      errorToast("Something went wrong", e.message);
    }
  }

  return (
    <div className="w-full">
      <h1 className="text-xl text-[#670006] mx-3 mt-3 font-semibold">Order History</h1>

      {
        invoices && invoices.length > 0
          ? <table>
            <thead>
              <tr>
                <th className="text-[#670006] text-center">S.No.</th>
                <th className="text-[#670006]">Date</th>
                <th className="text-[#670006]">Customer Name</th>
                <th className="text-[#670006]">Actions</th>
                <th className="text-[#670006]">Bill Created By</th>
              </tr>
            </thead>
            <tbody>
              {[...invoices].reverse().map((invoice, index) => (
                <tr key={invoice.id}>
                  <td className='text-sm text-center'>{invoices.length - index}</td>
                  <td className='text-sm'>{formatDate(invoice.data.createdAt)}</td>
                  <td className='text-sm'>{invoice.data.customerName}</td>
                  <td className='text-sm text-blue-500 underline'><Link to={`/view-invoice/${`${invoice.id} ${invoices.length - index}`}`}>View</Link></td>
                  <td className='text-sm'>{invoice.data.createdBy}</td>
                </tr>
              ))}
            </tbody>
          </table>
          : <h2 className="text-xl text-[#670006] text-center">No Invoices to Display</h2>
      }

    </div>
  );
};

export default Orders;